import React from "react";
import { RichText, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import BtnElevator from "../../shared/BtnElevator";
import fieldImage from "../../helpers/app/jss/fieldTypes/Image";

import "./styles.scss";

export default class IntroText extends React.Component {
  constructor(props) {
    super(props);

    let _bgimgurl = getFieldValue(props.fields, "bgimgurl");
    _bgimgurl = fieldImage(_bgimgurl);

    this.state = {
      settings: {
        stylesInline: {
          backgroundImage: _bgimgurl.backgroundSrc,
        },
        containerClass: _bgimgurl.hasBackground
          ? "c-IntroText c-IntroText--bg"
          : "c-IntroText",
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (getFieldValue(prevProps.fields, "bgimgurl")?.src !==  getFieldValue(this.props.fields, "bgimgurl")?.src) {
      this.updateSettings(this.props.fields);
    }
  }

  updateSettings = (fields) => {
    let _bgimgurl = getFieldValue(fields, "bgimgurl");
    _bgimgurl = fieldImage(_bgimgurl);

    const settings = {
      stylesInline: {
        backgroundImage: _bgimgurl.backgroundSrc,
      },
      containerClass: _bgimgurl.hasBackground
        ? "c-IntroText c-IntroText--bg"
        : "c-IntroText",
    };

    this.setState({ settings });
  }

  render() {
    const { fields } = this.props;
    const { stylesInline, containerClass } = this.state.settings;
    return (
      <IntroTextRenderer
        fields={fields}
        containerClass={containerClass}
        stylesInline={stylesInline}
        other={this.props}
      />
    );
  }
}

export function IntroTextRenderer({
  fields,
  containerClass,
  stylesInline,
  other,
}) {
  const _textbig = getFieldValue(fields, "textbig");
  const _textsmall = getFieldValue(fields, "textsmall");
  const _button = getFieldValue(fields, "button");
  const has_button = !!(_button && _button.href !== "");
  const fieldNoFollow = getFieldValue(fields, "noFollow");

  let _containerContent = getFieldValue(fields, "containerContent");
  const _containerContentClassName = _containerContent
    ? "c-IntroText--wide"
    : "c-IntroText--narrow";
  _containerContent =
    typeof _containerContent !== "undefined" ? _containerContent : false;

  return (
    <PlaceholderComponent withContainer={_containerContent} {...other}>
      <div
        className={`${containerClass} ${_containerContentClassName}`}
        style={stylesInline}
      >
        {_textbig && (
          <RichText field={fields.textbig} className="c-IntroText__text" />
        )}

        {_textsmall && (
          <RichText field={fields.textsmall} className="c-IntroText__desc" />
        )}

        {has_button && (
          <div className="c-IntroText__button">
            <BtnElevator field={_button} noFollow = {fieldNoFollow}/>
          </div>
        )}
      </div>
    </PlaceholderComponent>
  );
}
